import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import DeckGL from '@deck.gl/react'
import { FlyToInterpolator } from 'deck.gl'
import { StaticMap } from 'react-map-gl'

import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { useTranslation } from 'react-i18next'
import { useGetData } from '../../hooks'

import { AppContext } from '../../context'
import { PROVINCES } from '../../dashboard/constants'
import { GeoJsonLayer } from '../../layers/geojson-layer'


const INIT_VIEW_STATE = {
  pitch: 0,
  bearing: 0,
  transitionDuration: 2000,
  transitionInterpolator: new FlyToInterpolator()
}

const VIEW_STATES = {
  canada: {
    latitude: 57.127609,
    longitude: -101.731622,
    zoom: 3,
  },
  ontario: {
    latitude: 49.408364,
    longitude: -84.055321,
    zoom: 4,
  },
}

const ZOOM = {
  canada: {
    minZoom: 2,
    maxZoom: 14,
  },
  ontario: {
    minZoom: 6,
    maxZoom: 14,
  },
}

const LegendContainer = styled.div`
  width: 135px;
  height: 92px;
  overflow-x: 'scroll';
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: 9999;
  background-color: rgba(255,255,255,0.9);
  padding: 1rem;
  border-radius: 0.5rem;
`

const LegendText = styled.div`
  float: left;
  margin-left: 1rem;
  font-size: 2rem;
  color: black;
`

const LegendGradient = styled.div`
  float: left;
  width: 15px;
  height: 80px;
  background-image: linear-gradient( rgb(21, 0, 255), rgb(1, 0, 70));
`


const useCOVIDMap = (resolution) => {
  const [dataMin, setDataMin] = useState(0)
  const [dataMax, setDataMax] = useState(0)
  const { data: geoData, loading, error, getData } = useGetData({
    data: [],
    loading: true,
    loadOnFetch: false,
    dataCallback: data => {
      // TODO support data processing and customs state for MAX, MIN
      const { min, max } = data.cases.reduce(({ min, max }, { total_cases }) => ({
        min: Math.min(min === undefined ? total_cases : min, total_cases),
        max: Math.max(max === undefined ? total_cases : max, total_cases),
      }), {})
      setDataMin(min)
      setDataMax(max)
      return data.cases
    },
  })

  useEffect(() => { getData(`${resolution}/geo`)() }, [getData, resolution])

  const [x, setX] = useState(false)
  const [y, setY] = useState(false)
  const [hoveredObject, setHoveredObject] = useState(false)

  useEffect(() => {
    setX(false)
    setY(false)
    setHoveredObject(false)
  }, [resolution])

  const onHover = (params) => {
    const { x, y, object } = params
    setX(x)
    setY(y)
    setHoveredObject(object)
  }

  const [layers, setLayers] = useState([])
  useEffect(() => {
    if (geoData) {
      const hashMapData = geoData.reduce((agg, ele) => {
        agg[ele.geo_id] = ele
        return agg
      }, {})
      const { minZoom, maxZoom } = ZOOM[resolution]
      setLayers([GeoJsonLayer({
        minZoom,
        maxZoom,
        onHover,
        data: {
          type: 'FeatureCollection',
          features: geoData.map(({ geojson, geo_id }) => {
            const featureData = hashMapData[geo_id] || { total_cases: dataMin }
            const intensity = (parseInt(featureData.total_cases) - dataMin) / (dataMax - dataMin)
            return {
              type: 'Feature',
              geometry: geojson,
              properties: {
                ...(geojson.properties || {}),
                total_cases: featureData.total_cases,
                new_cases: featureData.new_cases,
                geo_id: featureData.geo_id,
                // each FSA compared to data range
                intensity,
              },
            }
          })
        },
      })])
    }
  }, [dataMax, dataMin, geoData, resolution])

  return { dataMax, x, y, hoveredObject, layers, loading, error, onHover }
}

const COVIDMap = ({ resolution = 'canada' }) => {
  const { isLightTheme } = useContext(AppContext)
  const { dataMax, x, y, hoveredObject, layers } = useCOVIDMap(resolution)
  const { t } = useTranslation()

  const _renderTooltip = () => {
    const numOfCases = hoveredObject ? parseInt(hoveredObject.properties.total_cases).toLocaleString() : null
    const { name } = hoveredObject ? PROVINCES.find(e => hoveredObject.properties.geo_id === e.code) : ''
    const newCases = hoveredObject ? parseInt(hoveredObject.properties.new_cases).toLocaleString() : ''

    return (hoveredObject && (
      <Card
        style={{
          position: 'absolute',
          zIndex: 1,
          left: x,
          top: y,
        }}
      >
         <CardContent>
          <Typography gutterBottom><strong>{t(name)}</strong></Typography>
          <Typography>{t('totalCases')}: {numOfCases}</Typography>
          <Typography>{t('newCases')}: {newCases}</Typography>
        </CardContent>
       </Card>
    )
    )
  }

  return (
    <div style={{ height: '100%', width: '100%', position: 'absolute' }}>
      <DeckGL
        initialViewState={{
          ...INIT_VIEW_STATE,
          ...VIEW_STATES[resolution],
        }}
        controller={true}
        layers={layers}
      // onHover={onHover}
      >
        {/*
          can use underlying mapbox ref
          https://github.com/uber/deck.gl/blob/master/docs/api-reference/mapbox/overview.md
          for use similar to
          https://github.com/EQWorks/snoke/blob/1ce40476b315a66ad54c68268971bab3c150d5e2/src/common-components/custom-kepler-layers/tile-layer.js
        */}

        <StaticMap
          mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
          mapStyle={isLightTheme === true ? 'mapbox://styles/mapbox/light-v9' : 'mapbox://styles/mapbox/dark-v9'}
        />
        {_renderTooltip}
        <LegendContainer>
          {dataMax === 0
            ?
            <CircularProgress style={{ marginTop: '15px' }} />
            :
            <>
              <LegendGradient />
              <LegendText>{`${dataMax.toLocaleString()} ${t('cases')}`}</LegendText>
              <LegendText style={{ marginTop: 38 }}>{`0 ${t('cases')}`}</LegendText>
            </>
          }

        </LegendContainer>
      </DeckGL>
    </div>
  )
}

export default COVIDMap
