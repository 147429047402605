import React, { useState, useMemo } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { useQueryParam, StringParam } from 'use-query-params'
import { makeStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { ThemeProvider } from 'styled-components'
import CssBaseline from '@material-ui/core/CssBaseline'

import extPalette from './theme/ext-palette'
import extTypography from './theme/ext-typography'
import paletteLight from './theme/paletteLight'
import paletteDark from './theme/paletteDark'
import typography from './theme/typography'

import { AppContext } from './context'
import TopNav from './top-nav'
import Dashboard from './dashboard'
import SmallBusinessHelper from './small-business-helper'
import Footer from './footer'


const useStyles = makeStyles((theme) => ({
  '@global': {
    html: {
      height: '100%',
      width: '100%',
      fontSize: '8px'
    },
    body: {
      height: '100%',
      width: '100%',
      '& > div': {
        height: '100%',
        width: '100%'
      }
    }
  },
  root: {
    height: '100%',
    width: '100%',
    margin: '0px',
    padding: '0px',
    fontFamily: 'Noto Sans, sans-serif',
  },
  mainContent: {
    width: '100%',
    offset: theme.mixins.toolbar,
    [theme.breakpoints.up('md')]: { padding: '20px 45px 0px 45px' },
    [theme.breakpoints.down('md')]: { overflowY: 'auto', height: '100%' },
  }
}))

const App = () => {
  const classes = useStyles()
  const [isLightTheme, setLightTheme] = useState(!(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches))
  const [location] = useQueryParam('location', StringParam)

  const theme = useMemo(
    () =>
      createMuiTheme({
        // TODO: fallback logic
        typography: location ? extTypography[location] : typography,
        palette: location
          ? extPalette[location]
          : isLightTheme
          ? paletteLight
          : paletteDark
      }),
    [location, isLightTheme]
  )

  return (
    <Router>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <div className={classes.root}>
            <CssBaseline />
            <AppContext.Provider value={{ isLightTheme, location }}>
              {!location && (
                <TopNav onChangeTheme={() => setLightTheme(!isLightTheme)} />
              )}
              <div className={classes.mainContent}>
                <Switch>
                  <Route exact path='/' component={Dashboard} />
                  <Route path='/gift-local' component={SmallBusinessHelper} />
                  <Redirect from='/flaat-map' to='/gift-local' />
                </Switch>
              </div>
              {!location && <Footer className={classes.footer} />}
            </AppContext.Provider>
          </div>
        </ThemeProvider>
      </MuiThemeProvider>
    </Router>
  )
}

export default App
