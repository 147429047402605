import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'


const useStyles = makeStyles((theme) => ({
  modalContainer: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    width: '646px',
    [theme.breakpoints.down('sm')]: { 
      textAlign: 'center',
      width: '333px',
      height: '436px',
    },
    height: '290px',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '4px',
    boxShadow: '0 3px 12px 0 rgba(0, 0, 0, 0.16)',
    outline: 'none',
    padding: theme.spacing(2, 4, 3),
  },
  modalBody: { height: '100%' },
  infoText: { color: theme.palette.text.secondary },
  submitButtonEnabled: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    '&:hover': { background: theme.palette.primary.main }
  },
  openButton: {
    color: theme.palette.primary.main,
    [theme.breakpoints.up ('md')]: { paddingLeft: 0 },
    textTransform: 'none',
    textDecoration: 'underline',
    "&:hover": {backgroundColor: 'transparent'}
  },
  closeButton: {textTransform: 'none'},
  emailInput: { width: '25ch' },
}))

const HowItWorksModal = () => {
  const { t } = useTranslation()
  const { modalContainer, modalBody, openButton, closeButton } = useStyles()
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  return (
    <>
      <Button
        disableRipple
        disableFocusRipple
        className={openButton}
        onClick={handleOpen}
      >
        {t('howDoesItWork')}
      </Button>
      <Button
        disableRipple
        disableFocusRipple
        className={openButton}
        href='mailto:inquiry@flaat.io'
      >
        {t('contactUs')}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='how-it-works'
      >
        <div className={modalContainer}>
          <Grid className={modalBody} container alignItems='flex-end'>
            <Grid item xs={12}>
              <Typography variant='h5'>
                {t('howDoesPlatformWork')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body1'>
                {t('howitWorksContent')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant='contained'
                color='primary'
                className={closeButton}
                onClick={handleClose}
              >
                {t('close')}
              </Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </>
  )
}

export default HowItWorksModal
