import React from 'react'
import styled from 'styled-components'

import { Timeline, Follow } from 'react-twitter-widgets'


const TwitterCont = styled.div`
  width: 100%;
  min-height: 400px;
  height: 100%;
`

const TwitterFollowButtonCont = styled.div`
  text-align: end;
`

export const TwitterProfile = ({ screenName, userName, isLightTheme }) => {
  return (
    <TwitterCont>
      <TwitterFollowButtonCont>
        <Follow username={userName}></Follow>
      </TwitterFollowButtonCont>
      <Timeline
        dataSource={{
          sourceType: 'profile',
          screenName: screenName
        }}
        options={{
          theme: isLightTheme ? 'light' : 'dark',
          username: userName,
          height: '400'
        }}
      />
    </TwitterCont>
  )
}

export default TwitterProfile
