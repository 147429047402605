import React, { useState, useContext } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Popover from '@material-ui/core/Popover'
import FormGroup from '@material-ui/core/FormGroup'
import MenuItem from '@material-ui/core/MenuItem'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import Chip from '@material-ui/core/Chip'
import Pagination from '@material-ui/lab/Pagination'

import MapIcon from '@material-ui/icons/Map'
import ListIcon from '@material-ui/icons/List'
import FilterListIcon from '@material-ui/icons/FilterList'
import SortIcon from '@material-ui/icons/Sort'

import { AppContext } from '../context'
import InfoImage from './info-image'
import Placeholder from '../biz-list/placeholder-item'
import { useTranslation } from 'react-i18next'


const useStyles = makeStyles((theme) => ({
  categoryDiv: {
    paddingTop: theme.spacing(1),
    marginBottom: '2rem',
    '& > *': { margin: theme.spacing(0.5) }
  },
  // separate class to differentiate between List and Map
  leftContentMobile: { flex: '1 !important' },
  noNavBar: { [theme.breakpoints.up('md')]: { height: '80vh !important' } },
  leftContentItem: {
    [theme.breakpoints.up('md')]: {
      '&::-webkit-scrollbar': { display: 'none' },
      height: '65vh',
    },
    [theme.breakpoints.down('md')]: {
      '&.MuiGrid-item': {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        marginTop: theme.spacing(1.5),
        flex: 'none',
        flexWrap: 'nowrap',
        // chrome doesn't appear to enforce 'flex: 1', but Safari does
        maxHeight: '65vh',
      }
    }
  },
  filterBlock: { flex: 'none', alignSelf: 'flex-start' },
  listBlock: {
    flex: 1,
    overflow: 'scroll',
    '&::-webkit-scrollbar': { display: 'none' },
  },
  pageBlock: { flex: 'none', alignSelf: 'flex-end' },
  distanceDropdown: { [theme.breakpoints.up('md')]: { marginLeft: 'auto' } },
  distanceMenu: { '& ul': { paddingTop: 0 } },
  popoverMenuSection: {
    padding: theme.spacing(2),
    paddingTop: 0,
  },
  menuSelect: { width: '100%' },
  categoryFilters: {
    paddingBottom: 0,
    '& span': { fontSize: theme.typography.subtitle1.fontSize },
  },
  filterMenu: {
    '& .MuiPopover-paper': {
      padding: theme.spacing(0.5),
      boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    }
  },
  sortBy: { fontSize: theme.typography.body1.fontSize },
  flexChip: {
    fontSize: theme.typography.subtitle1.fontSize,
    [theme.breakpoints.down('md')]: { flexGrow: 1 },
  },
  primaryChip: {
    color: theme.palette.chipButton.activeText,
    backgroundColor: theme.palette.chipButton.activeColor,
    borderColor: theme.palette.chipButton.activeColor,
  },
  secondaryChip: {
    borderColor: theme.palette.chipButton.inactiveColor,
    color: theme.palette.chipButton.inactiveText,
  },
  chipBtn: {
    '&.MuiChip-clickable.MuiChip-outlined:hover': {
      color: theme.palette.chipButton.activeText,
      backgroundColor: theme.palette.chipButton.activeColor,
      borderColor: theme.palette.chipButton.activeColor,
    },
    '&.MuiChip-clickable.MuiChip-outlined:focus': {
      color: theme.palette.chipButton.activeText,
      backgroundColor: theme.palette.chipButton.activeColor,
      borderColor: theme.palette.chipButton.activeColor,
    },
  },
  loader: { width: '100%', height: '100%', position: 'relative' },
  dimmer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.dimmer.backgroundColor,
    opacity: 0.6,
  },
}))

export default function LeftContent ({
  businessesLoading,
  businessesError,
  list,
  businessPage,
  handleCategoryFilter,
  categoryFilter,
  setEGiftFilter,
  eGiftFilter,
  nameFilter,
  handleNameFilter,
  sort,
  handleSort,
  filteredBusinesses,
  rowsPerPage,
  page,
  setPage,
  isMobile,
  shownContent,
  handleShownContent,
  start,
  currentCategories
}) {
  const classes = useStyles()
  const { location } = useContext(AppContext)
  const [categoryAnchorEl, setCategoryAnchorEl] = useState(null)
  const [sortAnchorEl, setSortAnchorEl] = useState(null)

  const chipClasses = {
    root: classes.flexChip,
    outlinedPrimary: classes.primaryChip,
    outlinedSecondary: classes.secondaryChip,
    iconColorPrimary: classes.primaryChip,
    iconColorSecondary: classes.secondaryChip,
  }

  const filtersApplied = categoryFilter.length + (nameFilter !== '' && 1) + (eGiftFilter !== 'All' && 1)
  const { t } = useTranslation()

  return (
    <Grid
      container
      direction='column'
      item
      xs={12}
      className={clsx(classes.leftContentItem, {
        [classes.leftContentMobile]: isMobile && shownContent === 'List',
        [classes.noNavBar]: location,
      })}
    >
      {(!businessesLoading && businessesError) && (
        <Grid item xs={12} className={classes.filterBlock}><Typography variant='h5'>{t('errorLoadingData')}</Typography></Grid>
      )}
      {!businessesError && (
        <Grid item xs={12} container className={classes.filterBlock}>
          <Grid item xs={12} container className={classes.categoryDiv}>
            {!isMobile && currentCategories.slice(0, 3).map(name => (
              <Chip
                classes={{
                  ...chipClasses,
                  outlinedPrimary: clsx(classes.primaryChip, classes.chipBtn),
                }}
                color={categoryFilter.includes(name) ? 'primary' : 'secondary'}
                key={name}
                label={t(name)}
                variant='outlined'
                onClick={handleCategoryFilter(name)}
              />)
            )}
            <Chip
              classes={{
                ...chipClasses,
                outlinedPrimary: clsx(classes.primaryChip, classes.chipBtn),
              }}
              color={filtersApplied > 0 ? 'primary' : 'secondary'}
              icon={<FilterListIcon fontSize='large'/>}
              label={filtersApplied > 0 ? `${filtersApplied} ${t(`Filter${filtersApplied > 1 ? 's' : ''} Applied`)}` : `${t('allFilters')}`}
              variant='outlined'
              onClick={(e) => setCategoryAnchorEl(e.currentTarget)}
            />
            <Popover
              id='filter-menu'
              anchorEl={categoryAnchorEl}
              open={Boolean(categoryAnchorEl)}
              onClose={() => setCategoryAnchorEl(null)}
              className={classes.filterMenu}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <FormGroup className={clsx(classes.popoverMenuSection, classes.menuSelect)}>
                <InputLabel shrink>{t('filterByCard')}</InputLabel>
                <Select
                  className={classes.sortBy}
                  value={eGiftFilter}
                  onChange={({ target: { value } }) => setEGiftFilter(value)}
                  MenuProps={{ disableScrollLock: true }}
                >
                  <MenuItem value='All'>{t('all')}</MenuItem>
                  <MenuItem value='eGift Card'>{t('eGiftCard')}</MenuItem>
                  <MenuItem value='No eGift Card'>{t('noEGiftCard')}</MenuItem>
                </Select>
              </FormGroup>
              <FormGroup className={clsx(classes.popoverMenuSection, classes.menuInput)}>
                <TextField
                  id='name-filter'
                  placeholder='e.g. Eggsmart'  
                  fullWidth
                  label={t('filterByName')}
                  InputLabelProps={{ shrink: true }}
                  value={nameFilter}
                  onChange={handleNameFilter}
                  autoComplete='off'
                />
              </FormGroup>
              <FormGroup className={clsx(classes.popoverMenuSection, classes.categoryFilters)}>
                <InputLabel shrink>{t('filterByCategory')}</InputLabel>
                {currentCategories.map(name => (
                  <FormControlLabel
                    key={name}
                    control={
                      <Checkbox
                        checked={categoryFilter.includes(name)}
                        name={name}
                        color='primary'
                        onClick={handleCategoryFilter(name)}
                      />
                    }
                    label={t(name)}
                  />
                ))}
              </FormGroup>
            </Popover>
            {isMobile ? (
              <>
                <Chip
                  classes={chipClasses}
                  color='secondary'
                  icon={<SortIcon fontSize='large'/>}
                  label={t('sort')}
                  variant='outlined'
                  onClick={(e) => setSortAnchorEl(e.currentTarget)}
                  disabled={shownContent === 'Map'}
                />
                <Popover
                  id='sort-menu'
                  anchorEl={sortAnchorEl}
                  open={Boolean(sortAnchorEl)}
                  onClose={() => setSortAnchorEl(null)}
                  className={classes.filterMenu}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <MenuItem onClick={() => handleSort({ key: 'distance', dir: 1 })}>{t('distanceClosest')}</MenuItem>
                  <MenuItem onClick={() => handleSort({ key: 'name', dir: 1 })}>{t('nameAsc')}</MenuItem>
                  <MenuItem onClick={() => handleSort({ key: 'name', dir: -1 })}>{t('nameDesc')}</MenuItem>
                </Popover>
              </>
            ) : (
                <FormControl className={classes.distanceDropdown}>
                  <Select
                    value={`${sort.key}/${sort.dir}`}
                    onChange={({ target: { value } }) => handleSort({ key: value.split('/')[0], dir: parseInt(value.split('/')[1]) })}
                    MenuProps={{
                      className: classes.distanceMenu,
                      disableScrollLock: true,
                    }}
                  >
                    <MenuItem value='distance/1'>{t('distanceClosest')}</MenuItem>
                    <MenuItem value='name/1'>{t('nameAsc')}</MenuItem>
                    <MenuItem value='name/-1'>{t('nameDesc')}</MenuItem>
                  </Select>
                </FormControl>
              )}
            {isMobile && (<Chip
              classes={chipClasses}
              color='secondary'
              icon={shownContent === 'Map' ? <ListIcon fontSize='large'/> : <MapIcon fontSize='large'/>}
              label={shownContent === 'Map' ? 'List' : 'Map'}
              variant='outlined'
              onClick={handleShownContent}
            />)}
          </Grid>
        </Grid>
      )}
      {(!businessesLoading && businessesError) && (
        <Grid item xs={12}>
          <Typography variant='h5'>{t('errorLoadingData')}</Typography>
        </Grid>
      )}
      {businessesLoading && (!isMobile || shownContent === 'List') && (
        <Grid item xs={12} className={classes.listBlock}>
          <div className={classes.loader}>
            <div className={classes.dimmer}>
              <CircularProgress color='primary' />
            </div>
            {new Array(10).fill(0).map((_, i) => <Placeholder key={i} />)}
          </div>
        </Grid>
      )}
      {!businessesLoading && (!isMobile || shownContent === 'List') && (
        <>
          {!filteredBusinesses.length ? (
            <InfoImage msg={start ? 'none' : 'start'} />
          ) : (
            <>
              <Grid item xs={12} className={classes.listBlock}>
                {list}
              </Grid>
              <Grid item xs={12} className={classes.pageBlock} container justify='center'>
                <Pagination
                  count={Math.ceil(filteredBusinesses.length / rowsPerPage)}
                  shape='rounded'
                  color='primary'
                  size='small'
                  showFirstButton
                  showLastButton
                  page={page}
                  onChange= {(event, page) => setPage(page)}
                />
              </Grid>
            </>
          )}
        </>
      )}
    </Grid>
  )
}