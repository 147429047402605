import { useEffect } from 'react'

import { useGetData } from '../hooks'


export const useDashboard = (resolution) => {
  const {
    data: timeData,
    loading: timeLoading,
    error: timeError,
    getData,
  } = useGetData({
    data: false,
    loading: true,
    loadOnFetch: false,
    dataCallback: data => data.cases.sort((a, b) => new Date(a.date) - new Date(b.date)),
  })

  useEffect(() => { getData(`canada${resolution !== 'National' ? `?province=${resolution}` : ''}`)() }, [getData, resolution])

  const {
    data: provinceData,
    loading: provinceLoading,
    error: provinceError,
    getData: getProvinceData,
  } = useGetData({
    data: false,
    loading: true,
    loadOnFetch: false,
    dataCallback: data => data.cases,
  })

  useEffect(() => { getProvinceData('canada/province')() }, [getProvinceData])

  const {
    data: statsData,
    loading: statsLoading,
    error: statsError,
    getData: getStatsData,
  } = useGetData({
    data: false,
    loading: true,
    loadOnFetch: false,
    dataCallback: data => data.stats,
  })

  useEffect(() => { getStatsData(`canada/stats?location=${resolution === 'National' ? 'Canada' : resolution}`)() }, [getStatsData, resolution])

  const totalCanada = provinceData ? provinceData.reduce((agg, ele) => agg + parseInt(ele.total_cases), 0) : 0
  const mostRecent = (timeData && timeData.length) ? timeData[timeData.length - 1] : {}
  const dayBefore = (timeData && timeData.length > 1) ? timeData[timeData.length - 2] : {}

  const loading = timeLoading || provinceLoading || statsLoading
  const error = timeError || provinceError || statsError

  return {
    mostRecent,
    dayBefore,
    // line chart log scale doesn't work with 0
    timeData: timeData ? timeData.map(o => ({ ...o, number_of_cases: Math.max(o.number_of_cases, 1) })) : false,
    provinceData,
    totalCanada,
    statsData,
    loading,
    error,
  }
}
