import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { useNotificationForm } from './hooks'
import { useTranslation } from 'react-i18next'
import { DynamicButton } from '@eqworks/react-labs'


const useStyles = makeStyles((theme) => ({
  modalContainer: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    width: '500px',
    [theme.breakpoints.down('sm')]: { width: '300px' },
    height: '349px',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '4px',
    boxShadow: '0 3px 12px 0 rgba(0, 0, 0, 0.16)',
    padding: theme.spacing(2, 4, 3),
    textAlign: 'center',
    outline: 'none',
  },
  modalBody: { height: '100%' },
  infoText: { color: theme.palette.text.secondary },
  submitButtonEnabled: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    '&:hover': {background: theme.palette.primary.main}
  },
  submitButtonDisabled: {borderStyle: '1px solid',},
  cancelButton: {
    color: theme.palette.primary.main,
    textTransform: 'none',
    textDecoration: 'underline',
  },
  emailInput: { marginTop: theme.spacing(1) },
}))

const RequestModal = ({ request, dispatch, handleNotify }) => {
  const { modalOpen, currentBusiness, loading, error } = request
  const { modalContainer, modalBody, infoText, cancelButton, emailInput,  } = useStyles()
	const {
    form: {
      email,
      invalid,
    },
    dispatch: formDispatch,
  } = useNotificationForm()

  const isDisabled = (!email.length || invalid || loading) ? true : false 
  const { t } = useTranslation()
  
  return (
    <Modal
      open={modalOpen}
      onClose={() => dispatch({ type: 'modalOpen', payload: false })}
    >
  		<div className={modalContainer}>
        <Grid className={modalBody} container justify='center'>
          <Grid item xs={12}>
            <Typography variant='h6'>
              {t('submissionSuccess')}
            </Typography>
            <Typography className={infoText} variant='body2'>
              {/* eslint-disable-next-line */}
              {t('getinTouchFeedback')} {t('notifyEmail')}
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <TextField
              id='email'
              value={email}
              onChange={(e) => formDispatch({ type: 'email', payload: e.target.value })}
              variant='outlined'
              autoComplete='off'
              label={t('email')}
              labelWidth={70}
              className={emailInput}
              helperText={invalid && invalid}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            {error && error}
            <DynamicButton disabled={isDisabled} onClick={()=>handleNotify(email, currentBusiness)}>{t('notify')}</DynamicButton>
          </Grid>
          <Grid item xs={12}>
            <Button
              className={cancelButton}
              onClick={() => dispatch({ type: 'modalOpen', payload: false })}
            >
              {t('noThanks')}
            </Button>
          </Grid>
        </Grid>
  		</div>
    </Modal>
	)
}

export default RequestModal
