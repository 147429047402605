import { useReducer } from 'react'
import { getAxios } from '../utils/api'


export const useGetData = ({ data, loading, loadOnFetch = true, dataCallback }) => {
  const [state, dispatch] = useReducer((state, { type, payload }) => {
      const value = { [type]: payload }
      // reset error when loading or data is received
      if ((type === 'loading' && payload) || type === 'data') {
        value.error = false
      }
      return {
        ...state,
        ...value,
      }
  }, {
    data,
    loading,
    error: false,
    getData: url => async () => {
      if (loadOnFetch) dispatch({ type: 'loading', payload: true })
      getAxios()
        .get(url)
        .then(({ data }) => dispatch({ type: 'data', payload: dataCallback(data) }))
        .catch(e => dispatch({ type: 'error', payload: true }))
        .finally(() => dispatch({ type: 'loading', payload: false }))
    },
  })

  return { ...state, dispatch }
}
