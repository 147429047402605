// core 3rd party dependencies
import React, { useState } from 'react'
// UI 3rd party dependencies
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import FormControl from '@material-ui/core/FormControl'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import {  DynamicDataTable } from '@eqworks/react-labs'
// internal dependencies
import { Line } from '../charts'
import { TwitterList } from '../twitter'
// local dependencies
import { PROVINCES } from './constants'
import { useTranslation } from 'react-i18next'


const LeftContentItem = ({
  timeData,
  resolution,
  provinceData,
  classes: { leftContentItem, card, widgetTitleBlock, chartDiv },
}) => {
  const [scale, setScale] = useState('log')
  const { t } = useTranslation()
  const enhancedProvinceData = provinceData.map(row => ({
      'province': t(row.prname),
      [t('newCases')]: row.new_cases,
      [t('totalCases')]: row.total_cases,
      [`${t('increase')} (%)`]: `${Math.round((row.new_cases / (row.total_cases - row.new_cases) || 0) * 1000) / 10}%`,
    })
  )

  return (
    <Grid className={leftContentItem} item xs={12} md={5}> {/*grid proportion*/}
      <Grid container spacing={2}> {/*spacing between cards*/}
        {timeData.length ? (
            <Grid item xs={12}>
              <Paper className={card} variant='outlined'>
                <Typography className={widgetTitleBlock} variant='subtitle2' gutterBottom>{t('numberOfCasesOverTime')}</Typography>
                <FormControl component='fieldset'>
                  <Tabs
                    value={scale}
                    onChange={(_, scale) => setScale(scale)}
                    indicatorColor='primary'
                    textColor='primary'
                    centered
                  >
                    <Tab label={t('logScale')} value='log' />
                    <Tab label={t('linearScale')} value='linear' />
                  </Tabs>
                </FormControl>
                <div className={chartDiv}>
                  <Line
                    data={timeData}
                    dateKey='date'
                    scaleType={scale}
                    valueTotal='total_cases'
                    valueNew='number_of_cases'
                  />
                </div>
              </Paper>
            </Grid>
        ) : (
          <Grid item xs={resolution === 'National' ? 6 : 4}>
            <Paper className={card} variant='outlined'>
              There's no data for {(PROVINCES.find(o => o.code === resolution) || { name: 'Canada' }).name}
            </Paper>
          </Grid>
        )}
        <Grid item xs={12}>
          <Paper className={card} variant='outlined'>
            <Typography className={widgetTitleBlock} variant='subtitle2' gutterBottom>{t('numberOfCasesbyProvince')}</Typography>
            <DynamicDataTable data={enhancedProvinceData} />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={card} variant='outlined'>
            <TwitterList sourceType={'list'} userName={'eqworkscorp'} listId={'1251168662813319169'}></TwitterList>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default LeftContentItem
