import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'


const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    marginBottom: '20px',
    padding: '12px 24px',
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '4px',
    overflowWrap: 'break-word',
  },
  placeholder: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.secondary.main,
  },
  title: { width: '60%', height: '20px', marginBottom: '15px' },
  filler: { width: '80%', height: '10px', marginBottom: '5px' },
  filler2: { width: '100%', height: '10px', marginBottom: '5px' }
}))

const Placeholder = () => {
  const { container, placeholder, title, filler, filler2 } = useStyles()
  return (
    <Paper className={container}>
      <Grid item xs={1}/>
      <Grid item xs={11}>
        <div className={clsx(placeholder, title)} />
        <div className={clsx(placeholder, filler)} />
        <div className={clsx(placeholder, filler2)} />
      </Grid>
    </Paper>
  )
}

export default Placeholder
