import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import App from './components/app'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'

import './i18n'


ReactDOM.render(
  <React.StrictMode>
    <Router>
      <QueryParamProvider ReactRouterRoute={Route}>
        <Suspense fallback={null}>
          <App />
        </Suspense>
      </QueryParamProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)
