// core 3rd party dependencies
import React from 'react'
import { useQueryParam, StringParam } from 'use-query-params'
// UI 3rd party dependencies
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import CircularProgress from '@material-ui/core/CircularProgress'
// internal dependencies
import CovidMap from '../maps/covid-map'
// local dependencies
import { useDashboard } from './hooks'
import TitleBlock from './title-block'
import LeftContentItem from './left-content-item'
import Stats from './stats'


export const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    textAlign: 'center',
    height: '100%'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  map: { padding: 0, position: 'relative', height: '100%', minHeight: '300px' },
  widgetTitleBlock: {
    paddingTop: '8px',
    paddingBottom: '16px'
  },
  title: { [theme.breakpoints.up('md')]: { flexGrow: 1 } },
  textBlock: { margin: '8px' },
  content: { padding: '32px' },
  chartDiv: { width: '100%', height: '300px', paddingTop: '24px' },
  mainWrapper: { [theme.breakpoints.up('md')]: { maxHeight: '100vh' } },
  leftContentItem: {
    [theme.breakpoints.up('md')]: {
      height: '85vh',
      overflow: 'scroll',
      '&::-webkit-scrollbar': { display: 'none' }
    }
  }
}))

const Dashboard = () => {
  // style hook
  const classes = useStyles()
  // control hook
  const [resolution = 'National', setResolution] = useQueryParam('resolution', StringParam)
  // data hook
  const {
    mostRecent,
    dayBefore,
    timeData,
    provinceData,
    totalCanada,
    statsData,
    loading,
    error,
  } = useDashboard(resolution)
  return (
    <Grid className={classes.content} container spacing={3}>
      <TitleBlock {...{ loading, error, mostRecent, resolution, setResolution, classes }} />
      {loading && <Grid item xs={12}><CircularProgress color='secondary' /></Grid>}
      {(!loading && !error) && (
        <>
          <Stats {...{
            resolution,
            mostRecent,
            dayBefore,
            totalCanada,
            statsData,
            timeData
          }} />
          <Grid className={classes.mainWrapper} container spacing={2}>
            <LeftContentItem {...{
              timeData,
              resolution,
              provinceData,
              classes,
            }} />
            {/* right content item */}
            <Grid item xs={12} md={7}>
              <Paper className={`${classes.card} ${classes.map}`} elevation={0}>
                <CovidMap />
              </Paper>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default Dashboard
