import React from 'react'
import moment from 'moment'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { useTranslation } from 'react-i18next'


const timeFormat = d => moment(d).utc().format('MMM Do')

const CustomTooltip = ({ active, payload, label }) => {
  const { t } = useTranslation()

  if (active) {
    return (
      <Card variant='outlined'>
        <CardContent>
          {/* Add this when having a legend */}
          {/* <div className={classes.tooltipNode}></div> */}
          <Typography gutterBottom data-testid='date'>Date: {`${timeFormat(label)}`}</Typography>
          <Typography style={{ color: '#3542E4' }} data-testid='total-cases'>
            {t(`totalCases`)} : {`${payload[0].value.toLocaleString()}`}
          </Typography>
          <Typography style={{ color: '#E45235' }} data-testid='new-cases'>
            {t('newCases')} : {`${payload[1].value.toLocaleString()}`}
          </Typography>
        </CardContent>
      </Card>
    )
  }

  return null
}

export default CustomTooltip