import React, { useState, useEffect } from 'react'

import PeopleIcon from '@material-ui/icons/People' //population
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd' //tested
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn' //confimred
import SwapVerticalCircleIcon from '@material-ui/icons/SwapVerticalCircle' // rate
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline' //death
import { Icon } from '@material-ui/core'
import { loadCSS } from 'fg-loadcss'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { WidgetStats } from '@eqworks/react-labs'
import { useMobile } from '../small-business-helper/hooks'
import { population as pop } from './population'
import { useTranslation } from 'react-i18next'


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    height: '100%'
  },
  title: { padding: '8px' },
  widget: { '& > div > p > svg': { fontSize: '3.5rem' } }
}))

const Stats = ({
  mostRecent,
  resolution,
  dayBefore,
  totalCanada,
  statsData,
  timeData,
}) => {
  const classes = useStyles()
  const isMobile = useMobile()
  const { t } = useTranslation()

  const [population, setPopulation] = useState(pop[resolution])

  useEffect(() => {
    const node = loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css'),
    )
    setPopulation(pop[resolution])
    return () => {
      node.parentNode.removeChild(node)
    }
  }, [resolution])

  const Content = ({ content }) => {
    const jsx = content.map((entry, i) => (
      <Typography {...entry.props} key={i}>
        {entry.icon}{entry.content}
      </Typography>
    ))
    return (
      <>
        {jsx}
      </>
    )
  }

  const WidgetIcon = ({ title, content, children }) => {
    return (
      <Paper className={classes.paper} variant='outlined'>
        <Typography className={classes.title} variant='subtitle2' gutterBottom>{title}</Typography>
        <Content {...{ content }} />
        {children && (<>{children}</>)}
      </Paper>
    )
  }

  const rateTested = (statsData.tested * 100 / population).toFixed(2)
  const status = (mostRecent.total_cases * 100 / statsData.tested).toFixed(2)
  const infection = (mostRecent.total_cases * 100 / population).toFixed(2)

  const tested =
    <Content {...{
      content: [{
        props: { style: { marginTop: '20px' } },
        icon: <AssignmentIndIcon fontSize='large' style={{ marginRight: '10px' }} />,
        content: `${rateTested}% ${t('tested')}`,
      }],
    }}
    />

  const mobileData = [
    {
      icon: <PeopleIcon fontSize='large' style={{ marginRight: '10px' }} />,
      content: `${population.toLocaleString()} population`
    },
    {
      icon: <AssignmentIndIcon fontSize='large' style={{ marginRight: '10px' }} />,
      content: `${rateTested}% ${t('tested')}`
    },
    {
      icon: <AssignmentTurnedInIcon fontSize='large' style={{ marginRight: '10px' }} />,
      content: `${status}% ${t('confirmed')}`
    },
    {
      icon: <SwapVerticalCircleIcon fontSize='large' style={{ marginRight: '10px' }} />,
      content: `${infection}% ${t('infection')}`
    },
    {
      icon: <ErrorOutlineIcon fontSize='large' style={{ marginRight: '10px' }} />,
      content: `${statsData.recovered_rate}% ${t('recovered')}`
    },
    {
      icon: <ErrorOutlineIcon fontSize='large' style={{ marginRight: '10px' }} />,
      content: `${statsData.death_rate}% ${t('deathRate')}`
    }
  ]

  return (
    <Grid container justify='space-between' spacing={2} style={{ marginBottom: '8px' }}>
      {timeData.length && (
        <>

          <Grid item xs={6} md className={classes.widget}>
            <WidgetStats
              title={t('totalConfirmedCases')}
              value={mostRecent.total_cases}
              prev={dayBefore.total_cases || 1}
              trendInfo={{
                isTrendPercentage: true,
                upIsGreen: false,
                up: t('increase'),
                down: t('decrease'),
                comparedTo: t('sinceYesterday')
              }}
            />
          </Grid>
          <Grid item xs={6} md className={classes.widget}>
            <WidgetStats
              title={t('newConfirmedCases')}
              value={mostRecent.number_of_cases}
              prev={dayBefore.number_of_cases || 1}
              trendInfo={{
                isTrendPercentage: true,
                upIsGreen: false,
                up: t('increase'),
                down: t('decrease'),
                comparedTo: t('sinceYesterday')
              }}
            />
          </Grid>

          {isMobile ? (
            <Grid container item xs={12}>

              <Paper variant='outlined' style={{
                padding: '24px',
                textAlign: 'center',
                height: '100%',
                width: '100%',
              }}
              >
                {mobileData.map((stats, i) => (
                  <Content {...{
                    content: [{
                      props: {
                        variant: 'subtitle1',
                        paragraph: true
                      },
                      icon: stats.icon,
                      content: stats.content,
                    }],
                    key: i,
                  }} />
                ))}
                {resolution !== 'National' && (
                  <Typography variant='subtitle1'>
                    <Icon className='fab fa-canadian-maple-leaf' color='error' fontSize='large' style={{ marginRight: '10px' }} />
                    {Math.round((mostRecent.total_cases / totalCanada) * 100)}% {t('national')}
                  </Typography>
                )}
              </Paper>
            </Grid>
          ) : (
              <>
                <Grid item md>
                  <WidgetIcon {...{
                    title: 'Population',
                    content: [{
                      props: {
                        variant: 'h5',
                        gutterBottom: true
                      },
                      icon: <PeopleIcon fontSize='large' style={{ marginRight: '10px' }} />,
                      content: population.toLocaleString(),
                    }],
                  }}
                  >
                    {tested}
                  </WidgetIcon>
                </Grid>
                <Grid item md>
                  <WidgetIcon {...{
                    title: t('testStatus'),
                    content: [{
                      props: {
                        variant: 'h5',
                        gutterBottom: true
                      },
                      icon: <AssignmentTurnedInIcon fontSize='large' style={{ marginRight: '10px' }} />,
                      content: `${status}%`,
                    }],
                  }}
                  >
                    <Content content={[{ props: { style: { marginTop: '20px' } }, content: t('confirmed') }]} />
                  </WidgetIcon>
                </Grid>
                <Grid item md>
                  <WidgetIcon {...{
                    title: t('infection'),
                    content: [{
                      props: {
                        variant: 'h5',
                        gutterBottom: true
                      },
                      icon: <SwapVerticalCircleIcon fontSize='large' style={{ marginRight: '10px' }} />,
                      content: `${infection}%`,
                    }],
                  }}
                  >
                    <Content content={[{ props: { style: { marginTop: '20px' } }, content: t('infectionDescription') }]} />
                  </WidgetIcon>
                </Grid>
                <Grid item md>
                  <WidgetIcon {...{
                    title: t('updates'),
                    content: [{
                      props: {
                        variant: 'subtitle1',
                        gutterBottom: true
                      },
                      icon: <ErrorOutlineIcon fontSize='large' style={{ marginRight: '10px' }} />,
                      content: `${statsData.recovered_rate}% ${t('recovered')}`,
                    },
                    {
                      props: {
                        variant: 'subtitle1',
                        gutterBottom: true
                      },
                      icon: <ErrorOutlineIcon fontSize='large' style={{ marginRight: '10px' }} />,
                      content: `${statsData.death_rate}% ${t('deathRate')}`,
                    }],
                  }}
                  />
                </Grid>
                {resolution !== 'National' && (
                  <Grid item md>
                    <WidgetStats
                      title={t('nationalProportion')}
                      value={Math.round((mostRecent.total_cases / totalCanada) * 100)}
                      units='%'
                    >
                      {t('responsible')}{mostRecent.total_cases.toLocaleString()} {t('national')}
                </WidgetStats>
                  </Grid>
                )}
              </>
            )}
        </>
      )}
    </Grid>
  )
}

export default Stats
