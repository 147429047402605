import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'


const useStyles = makeStyles((theme) => ({
  footer: {
    padding: '0.5em',
    textAlign: 'center',
    fontSize: '1.75rem'
  },
  footerContant: {margin: 'auto'}
}))

const Footer = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <div className={classes.footer} >
        <div className={classes.footerContant} >
          <p> © 2020 Flaat. {t('poweredBy')}</p>
          <p>{t('dataSource')}</p>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Footer
