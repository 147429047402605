//The base pixel size is 8px.

const extTypography = {
    narcity: {
        fontFamily: "Roboto",
        h1: {
            fontSize: '12rem',
            fontWeight: 200
        },
        h2: {
            fontSize: '7.5rem',
            fontWeight: 300
        },
        h3: {
            fontSize: '6rem',
            fontWeight: 400
        },
        h4: {
            fontSize: '4.25rem',
            fontWeight: 500
        },
        h5: {
            fontSize: '3rem',
            fontWeight: 600
        },
        h6: {
            fontSize: '2.5rem',
            fontWeight: 700
        },
        subtitle1: {
            fontSize: '2rem',
            fontWeight: 600
        },
        subtitle2: {
            fontSize: '2.25rem',
            fontWeight: 400
        },
        body1: {
            fontSize: '1.75rem',
            fontWeight: 400
        },
        body2: {
            fontSize: '1.75rem',
            fontWeight: 400
        },
        button: {
            fontSize: '1.75rem',
            fontWeight: 400,
        },
        caption: {
            fontSize: '1.5rem',
            fontWeight: 400
        }
    }
}

export default extTypography
