import React, { useState, useCallback, useContext } from 'react'
import clsx from 'clsx'
import { debounce } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import SearchContainer from './search-container'
import {
  usePosition,
  useBusinesses,
  useRequests,
  useMobile,
} from './hooks'
import SmallBusinessMap from '../maps/small-business-map'
import ListItem from '../biz-list/list-item'
import LeftContent from './left-content'
import RequestModal from './request-modal'
import CircularProgress from '@material-ui/core/CircularProgress'

import { AppContext } from '../context'


const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    textAlign: 'center',
    height: '100%'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  textBlock: { margin: '8px' },
  content: {
    height: '100%',
    marginTop: 0,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      flexDirection: 'column',
      flexWrap: 'nowrap',
    },
  },
  noNavBar: { [theme.breakpoints.up('md')]: { height: '95vh !important' } },
  leftContentItem: {
    [theme.breakpoints.down('md')]: {
      '&.MuiGrid-item': {
        marginTop: theme.spacing(1.5),
        flex: 'none',
        flexWrap: 'nowrap',
        // chrome doesn't appear to enforce 'flex: 1', but Safari does
        maxHeight: '65vh',
      }
    }
  },
  rightContentItem: {
    [theme.breakpoints.up('md')]: { height: '85vh' },
    [theme.breakpoints.down('md')]: {
      flex: 1,
      '&.MuiGrid-item': { padding: 0 },
    },
  },
  hiddenItem: { display: 'none' },
  map: { padding: 0, position: 'relative', height: '100%', width: '100%' },
}))

const rowsPerPage = 10

const FlaatMap = () => {
  const classes = useStyles()
  const { location } = useContext(AppContext)
  // TODO a lot of lag between "Allow" and picking up values here
  const { position: userLocation, error } = usePosition()
  const [searchLocation, setSearchLocation] = useState({})
  const [activeBusiness, setActiveBusiness] = useState({})
  // don't pull data until the map has moved (from user location, search or pan)
  const [initialTransition, setInitialTransition] = useState(false)
  const [viewport, setViewport] = useState(false)
  const handleViewportChange = useCallback(debounce(({ n, w, s, e }) => {
    setViewport({ xmin: w, xmax: e, ymin: s, ymax: n })
  }, 200), [])
  const {
    start,
    businessesLoading,
    businessesError,
    sort,
    handleSort,
    page,
    setPage,
    nameFilter,
    handleNameFilter,
    eGiftFilter,
    setEGiftFilter,
    categoryFilter,
    handleCategoryFilter,
    filteredBusinesses,
    businessPage,
    expanded,
    setExpanded,
    highlight,
    setHighlight,
    mapBusinesses,
    categories,
  } = useBusinesses({ userLocation, searchLocation, viewport, initialTransition })

  const {
    request,
    dispatch,
    handleRequest, // opens modal on success
    handleNotify,
  } = useRequests()

  const list = businessPage
    .map((bus) => (
      <ListItem
        {...bus}
        key={bus.id}
        expanded={expanded}
        setExpanded={setExpanded}
        highlight={highlight}
        setHighlight={setHighlight}
        resetError={() => dispatch({ type: 'error', payload: false })}
        handleRequest={() => handleRequest(bus.id)}
        requested={request.requests[bus.id] && request.requests[bus.id].requested}
        requestError={request.error}
      />
    ))

  const isMobile = useMobile()
  const [shownContent, setShownContent] = useState('Map')
  const handleShownContent = () => setShownContent(shownContent === 'Map' ? 'List' : 'Map')

  const search = ({ id, lon: longitude, lat: latitude, type }) => {
    setSearchLocation({ id, longitude, latitude, zoom: type === 'location' ? 14 : 18 })
    setActiveBusiness({})
  }
  return (
    <Grid className={classes.content} alignContent='flex-start' container spacing={3}>
      <Grid item xs={12} md={4} className={classes.leftContentItem}>
        <SearchContainer search={search} />
        <LeftContent
          currentCategories={categories}
          start={start}
          businessesLoading={businessesLoading}
          businessesError={businessesError}
          list={list}
          categoryFilter={categoryFilter}
          handleCategoryFilter={handleCategoryFilter}
          eGiftFilter={eGiftFilter}
          setEGiftFilter={setEGiftFilter}
          nameFilter={nameFilter}
          handleNameFilter={handleNameFilter}
          sort={sort}
          handleSort={handleSort}
          filteredBusinesses={filteredBusinesses}
          rowsPerPage={rowsPerPage}
          page={page}
          setPage={setPage}
          isMobile={isMobile}
          shownContent={shownContent}
          handleShownContent={handleShownContent}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={8}
        className={clsx(classes.rightContentItem, {
          [classes.hiddenItem]: (isMobile && shownContent !== 'Map'),
          [classes.noNavBar]: location,
        })}
      >
        <Paper className={`${classes.card} ${classes.map}`} elevation={0}>
          { (!error && !userLocation.latitude && !searchLocation.latitude) && 
            <div style={{
              backgroundColor: 'rgba(0, 0, 0, 0.3)',
              position: 'absolute',
              width: '100%',
              height: '100%',
              zIndex: 10
            }}>
              <CircularProgress size='10rem' style={{marginTop: '8rem', opacity: 0.7}}/>
            </div>
          }
          <SmallBusinessMap
            businesses={mapBusinesses}
            userLocation={userLocation}
            searchLocation={searchLocation}
            setSearchLocation={setSearchLocation}
            activeBusiness={activeBusiness}
            handleViewportChange={handleViewportChange}
            initialTransition={initialTransition}
            handleInitialTransition={setInitialTransition}
            setActiveBusiness={setActiveBusiness}
            setHighlight={setHighlight}
            requests={request.requests}
            handleRequest={handleRequest}
            highlight={highlight}
            error={error}
          />
        </Paper>
      </Grid>
      <RequestModal
        request={request}
        dispatch={dispatch}
        handleNotify={handleNotify}
      />
    </Grid>
  )
}

export default FlaatMap
