import { useState, useEffect, useRef, useReducer, useCallback } from 'react'
import axios from 'axios'
import { getAxios } from '../../utils/api'
import { useTranslation } from 'react-i18next'


export const usePosition = () => {
  const [position, setPosition] = useState({})
  const [error, setError] = useState(null)

  const onChange = useCallback(({coords}) => {
    const rpLat = Math.round(position.latitude * 1000) / 1000
    const rcLat = Math.round(coords.latitude * 1000) / 1000
    const rpLon = Math.round(position.longitude * 1000) / 1000
    const rcLon = Math.round(coords.longitude * 1000) / 1000
    if (!position.latitude ||
      rpLat !== rcLat ||
      rpLon !== rcLon
    ) {
      setPosition({
        latitude: coords.latitude,
        longitude: coords.longitude,
        zoom: 14,
      })
    }
  }, [position])

  const onError = (er) => {
    setError({error: er, status: 'denied'})
  }

  useEffect(() => {
    const geo = navigator.geolocation
    if (!geo) {
      setError('Geolocation is not supported')
      return
    }
    const watcher = geo.watchPosition(onChange, onError)

    return () => geo.clearWatch(watcher)
  }, [onChange])

  return { position, error }
}

const initBusState = {
  start: false,
  loading: false,
  error: false,
  viewport: false,
  businesses: [],
  categories: [],
  filteredBusinesses: [],
  mapBusinesses: [],
  highlight: false,
  expanded: false,
  nameFilter: '',
  categoryFilter: [],
  eGiftFilter: 'All',
  page: 1,
  rowsPerPage: 10,
  sort: { key: 'distance', dir: 1 },
  businessPage: [],
}

const SIMPLE_STATES = ['start', 'loading', 'error', 'viewport', 'expanded', 'page']
const filterBusiness = ({ nameFilter, categoryFilter, eGiftFilter }) => o => (
  (nameFilter === '' || o.name.toLowerCase().indexOf(nameFilter.toLowerCase()) >= 0)
  && (!categoryFilter.length || categoryFilter.includes(o.category))
  && (eGiftFilter === 'All' || (eGiftFilter === 'eGift Card' ? o.egift_url : !o.egift_url))
)
const sortBusinesses = ({ key, dir }) => (a,b) => {
  if (key === 'distance') return (a[key] - b[key]) * dir
  let compare = 0
  if (a[key] < b[key]) compare = -1
  if (a[key] > b[key]) compare = 1
  return compare * dir
}

const paginateBusinesses = ({ filteredBusinesses, page, rowsPerPage, sort }) => {
  const startIdx = (page - 1) * rowsPerPage
  const endIdx = ((page - 1) * rowsPerPage) + rowsPerPage
  const businessPage = filteredBusinesses.sort(sortBusinesses(sort)).slice(startIdx, endIdx)
  return businessPage
}

const updateFilteredBusinesses = state => {
  const filteredBusinesses = state.businesses.filter(filterBusiness(state))
  const mapBusinesses = filteredBusinesses.map(bus => ({ ...bus, highlight: state.highlight === bus.id }))
  const businessPage = paginateBusinesses({ ...state, filteredBusinesses })
  return { filteredBusinesses, mapBusinesses, businessPage}
}

export const useBusinesses = ({ userLocation, searchLocation, viewport, initialTransition }) => {
  const [businessState, businessDispatch] = useReducer((state, { type, payload }) => {
    // states that affect filteredBusinesses (e.g. the "base" list)
    if (['businesses', 'nameFilter', 'eGiftFilter', 'categoryFilter'].includes(type)) {
      let change = payload

      if (type === 'categoryFilter') {
        if (state.categoryFilter.includes(payload)) {
          const idx = state.categoryFilter.indexOf(payload)
          change = [...state.categoryFilter.slice(0, idx), ...state.categoryFilter.slice(idx + 1)]
        } else {
          change = [...state.categoryFilter, payload]
        }
      }

      const newState = { ...state, [type]: change }

      if (type === 'businesses') {
        newState.error = false
        newState.start = true
        newState.categories = Object.keys(change.reduce((agg, { category }) => {
          agg[category] = true
          return agg
        }, {}))
        // TODO with conditional categories, do we reset the categories that are already chosen?
        // if not, we need to somehow show it
        newState.categoryFilter = []
      }
      const { filteredBusinesses, mapBusinesses, businessPage } = updateFilteredBusinesses(newState)
      return {
        ...newState,
        filteredBusinesses,
        mapBusinesses,
        businessPage,
        page: 1,
      }
    }  else if (['sort', 'page'].includes(type)) {
      return {
        ...state,
        [type]: payload,
        businessPage: paginateBusinesses({ ...state, [type]: payload }),
      }
    } else if (type === 'highlight') {
      // separate filteredBusinesses and mapBusinesses:
      // map layer requires a new "data" prop to trigger a rerender of the layer and get sizing/highlight effect
      return {
        ...state,
        highlight: payload,
        mapBusinesses: state.filteredBusinesses.map(bus => ({ ...bus, highlight: payload === bus.id })),
      }
    } else if (SIMPLE_STATES.includes(type)) {
      return {
        ...state,
        [type]: payload,
      }
    }
    // default is no change?
    return state
  }, initBusState)

  useEffect(() => {
    const getData = () => {
      getAxios()
        .get('/businesses/viewport', {
          params: {
            lat: searchLocation.latitude || userLocation.latitude,
            lon: searchLocation.longitude || userLocation.longitude,
            ...viewport,
          },
        })
        .then(({ data: { businesses} }) => {
          /*
            {
              name,
              category,
              address,
              lat,
              lon,
              egift_url
            }
          */
          businessDispatch({ type: 'businesses', payload: businesses })
        })
        .catch(() => businessDispatch({ type: 'error', payload: true }))
        .finally(() => businessDispatch({ type: 'loading', payload: false }))
    }
    if (initialTransition && viewport) {
      businessDispatch({ type: 'loading', payload: true })
      getData()
    }
  }, [viewport, userLocation, searchLocation, initialTransition])

  return {
    ...businessState,
    businessesError: businessState.error,
    businessesLoading: businessState.loading,
    handleSort: payload => businessDispatch({ type: 'sort', payload }),
    setPage: payload => businessDispatch({ type: 'page', payload }),
    handleNameFilter: event => businessDispatch({ type: 'nameFilter', payload: event.target.value }),
    setEGiftFilter: payload => businessDispatch({ type: 'eGiftFilter', payload }),
    handleCategoryFilter: payload => () => businessDispatch({ type: 'categoryFilter', payload }),
    setExpanded: payload => businessDispatch({ type: 'expanded', payload }),
    setHighlight: payload => businessDispatch({ type: 'highlight', payload }),
  }
}

const mapboxOptions = 'limit=5&country=ca&types=country%2Cregion%2Cdistrict%2Cpostcode%2Clocality%2Cplace%2Cneighborhood'
export const useBusinessSearch = () => {
  const searchInputRef = useRef(null)
  const [{ searchTerm, inputValue }, dispatch] = useReducer((state, { type, payload }) => {
    if (type === 'blur') {
      return {
        ...state,
        searchTerm: '',
      }
    }
    const value = { [type]: payload }
    if (type === 'searchTerm') {
      value.inputValue = payload
    } else if (type === 'inputValue') {
      value.searchTerm = ''
    }
    return {
      ...state,
      ...value,
    }
  }, { searchTerm: '', inputValue: '' })

  const [searchResults, setSearchResults] = useState([])
  const [searchResultsLoading, setSearchResultsLoading] = useState(true)
  const [searchResultsError, setSearchResultsError] = useState(false)

  useEffect(() => {
    if (searchTerm !== '') {

      (async () => {
        const [db, mapbox] = await Promise.all([
          getAxios().get(`/businesses/name?search=${searchTerm}`),
          axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${searchTerm}.json?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}&${mapboxOptions}`)
        ])
        .catch(() => setSearchResultsError(true))
        .finally(() => setSearchResultsLoading(false))

        let location = mapbox.data.features.map((loc, i) => ({
            id: `loc-${i}`,
            type: 'location',
            name: loc.place_name,
            lat: loc.center[1],
            lon: loc.center[0]
          }))
        let business = db.data.businesses.slice(0,5)

        setSearchResults([ ...business, ...location, ])
        setSearchResultsError(false)

      })()

    } else {
      setSearchResults([])
    }
  }, [searchTerm])

  return {
    searchInputRef,
    searchTerm,
    inputValue,
    blur: () => dispatch({ type: 'blur' }),
    setSearchTerm: payload => dispatch({ type: 'searchTerm', payload }),
    setInputValue: payload => dispatch({ type: 'inputValue', payload }),
    searchResults,
    searchResultsLoading,
    searchResultsError,
  }
}

export const useRequests = () => {
  const requests = window.localStorage.getItem('requests') ? JSON.parse(window.localStorage.getItem('requests')) : {}
  const [request, dispatch] = useReducer((state, { type, payload }) => {
    // special types
    if (type === 'startAPICall') {
      return {
        ...state,
        loading: true,
        error: false,
      }
    }

    // top-level keys
    if (['loading', 'error', 'modalOpen', 'currentBusiness'].includes(type)) {
      return {
        ...state,
        [type]: payload,
      }
    }

    // the rest affect { ... requests }
    let ret
    if (type === 'requestSuccess') {
      ret = {
        ...state,
        error: false,
        modalOpen: true,
        currentBusiness: payload,
        requests: {
          ...state.requests,
          [payload]: {
            ...state.requests[payload],
            requested: true,
          }
        },
      }
    } else if (type === 'notifySuccess') {
      ret = {
        ...state,
        error: false,
        modalOpen: false,
        requests: {
          ...state.requests,
          [payload]: {
            ...state.requests[payload],
            notified: true,
          }
        },
      }
    } else {
      ret = {
        ...state,
        requests: {
          // { [bus_id]: { notify: true, requested: true } }
          ...state.requests,
          [payload]: {
            ...state.requests[payload],
            [type]: true,
          }
        },
      }
    }

    window.localStorage.setItem('requests', JSON.stringify(ret.requests))
    return ret
  }, { modalOpen: false, currentBusiness: false, loading: false, error: false, requests })

  const handleRequest = (bus) => {
    dispatch({ type: 'startAPICall' })
    getAxios().post('/businesses/request', { bus })
    .then(res => {
      dispatch({ type: 'requestSuccess', payload: bus })
    })
    .catch(e => {
      const { message = '' } = ((e.response || {}).data || {})
      if (message.indexOf('foreign key constraint') >= 0) {
        dispatch({ type: 'error', payload: 'This business does not exist' })
      } else {
        dispatch({ type: 'error', payload: 'There was an error, please try again'})
      }
    })
    .finally(() => dispatch({ type: 'loading', payload: false }))
  }

  const handleNotify = (email, bus) => {
    dispatch({ type: 'startAPICall' })
    getAxios().post('/businesses/notify', { email, bus })
    .then(res => {
      dispatch({ type: 'notifySuccess', payload: bus })
    })
    .catch(e => {
      const { message = '' } = ((e.response || {}).data || {})
      if (message.indexOf('unique constraint') >= 0) {
        // already notified
        dispatch({ type: 'notifySuccess', payload: bus })
      } else if (message.indexOf('foreign key constraint') >= 0) {
        dispatch({ type: 'error', payload: 'This business does not exist' })
      } else {
        dispatch({ type: 'error', payload: 'There was an error, please try again'})
      }
    })
    .finally(() => dispatch({ type: 'loading', payload: false }))
  }

  return {
    request,
    dispatch,
    handleRequest,
    handleNotify,
  }
}

export const useNotificationForm = () => {
  const { t } = useTranslation()

  const [form, dispatch] = useReducer((state, { type, payload }) => {
    if (type === 'email') {
      let invalid = false
      if (payload.length > 100 || !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(payload)) {
        invalid = t('emailError')
      }
      return { ...state, email: payload, invalid }
    }
    return { ...state, [type]: payload }
  }, { email: '', invalid: false })

  return { form, dispatch }
}

export const useMobile = () => {
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth)
  const updateWidth = () => setViewportWidth(window.innerWidth)
  useEffect(() => {
    window.addEventListener('resize', updateWidth)
    return () => window.removeEventListener("resize", updateWidth)
    // eslint-disable-next-line
  }, [])

  const [isMobile, setIsMobile] = useState(false)
  useEffect(() => {
    setIsMobile(viewportWidth < 960) // TODO this is based on mui, but should test further
  }, [viewportWidth])

  return isMobile
}
