import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import BusinessIcon from '@material-ui/icons/Business'

import { titleCase } from '../../small-business-helper/helper'
import MapTooltip from '../map-tooltip-container'


const useStyles = makeStyles((theme) => ({
  tooltipCardPopup: {
    overflowY: 'scroll',
    '&::-webkit-scrollbar': { display: 'none' },
    maxWidth:'280px',
    backgroundColor: 'transparent',
    '& .MuiCardContent-root': {
      padding: '8px',
      '&:last-child': { paddingBottom: '16px' },
    },
  },
  suggestionDivItem: {
    cursor: 'pointer',
    display:'flex',
    justifyContent: 'flex-start',
    padding: '0.5rem',
    '&:hover': {backgroundColor: theme.palette.background.highlight},
    fontSize: theme.typography.body2
  },
}))

const MultiBusinessTooltip = ({ 
  clickedObject,
  setClickedObject,
}) => {
  const classes = useStyles()
  const { x, y, h, w } = clickedObject
  return (
    <MapTooltip x={x} y={y} h={h} w={w} translate>
      <Card
        variant='outlined'
        className={classes.tooltipCardPopup}
      >
        <CardContent>
          {clickedObject.multi.map(business => {
            return <div
            key={business.id}
            onClick={(e) => {
              e.stopPropagation()
              setClickedObject({ ...business, x, y, h, w })
            }}
            
            className={classes.suggestionDivItem}
            > 
              <BusinessIcon style={{marginRight: '5px', fontSize: '2rem'}} />
              <Typography align='left'>
                {titleCase(business.name)}
              </Typography>
            </div>
          })}
        </CardContent>
      </Card>
    </MapTooltip>
  )
}

export default MultiBusinessTooltip
