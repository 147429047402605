import React, { useContext } from 'react'
import styled from 'styled-components'

import { Timeline, Follow } from 'react-twitter-widgets'
import { AppContext } from '../context'


const TwitterCont = styled.div`
  width: 100%;
  min-height: 400px;
  height: 100%;
`

const TwitterFollowButtonCont = styled.div`
  text-align: end;
`

export const TwitterList = ({ sourceType, listId, userName='' }) => {
  const {isLightTheme} = useContext(AppContext)
  return (
    <TwitterCont data-testid='twitter-container'>
      <TwitterFollowButtonCont data-testid='follow-button'>
        <Follow username={userName}  options={{size: 'large'}}></Follow>
      </TwitterFollowButtonCont>
      <Timeline
        dataSource={{
          sourceType: sourceType,
          id: listId
        }}
        options={{
          theme: isLightTheme ? 'light' : 'dark',
          username: userName,
          height: '400'
        }}
      />
    </TwitterCont>
  )
}

export default TwitterList