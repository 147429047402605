export const population = {
'National': 37894799,
'Newfoundland and Labrador': 521365,
'Prince Edward Island': 158158,
'Nova Scotia': 977457,
'New Brunswick': 779993,
'Quebec': 8537674,
'Ontario': 14711827,
'Manitoba': 1377517,
'Saskatchewan': 1181666,
'Alberta': 4413146,
'British Columbia': 5110917,
'Yukon': 41078,
'Northwest Territories': 44904,
'Nunavut': 39097,
}