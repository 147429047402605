import React from 'react'
import moment from 'moment'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'

import { PROVINCES } from './constants'
import { useTranslation } from 'react-i18next'

// import PeopleIcon from '@material-ui/icons/People' //population
// import AssignmentIndIcon from '@material-ui/icons/AssignmentInd' //tested
// import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn' //confimred
// import SwapVerticalCircleIcon from '@material-ui/icons/SwapVerticalCircle' // rate
// import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline' //death


const TitleBlock = ({
  loading,
  error,
  mostRecent,
  resolution,
  setResolution,
  classes: { title, textBlock, formControl },
}) => {
  const { t } = useTranslation()

  return (
    <Grid container alignItems='center' justify='center'>
      <Typography className={title} variant='h5'>
        {t('insightHeader')} {resolution !== 'National' && `- ${resolution}`}
      </Typography>
      {(!loading && error) && <Typography variant='h5'>{t('errorLoadingData')}</Typography>}
      <div className={textBlock}>
        <FormControl component='fieldset' className={formControl}>
          <Select
            disabled={loading}
            value={resolution}
            onChange={({ target: { value } }) => setResolution(value)}
            MenuProps={{ disableScrollLock: true }}>
            <MenuItem value='National'>{t('National')}</MenuItem>
            {PROVINCES.map(({ code, name }) => <MenuItem value={code} key={code}>{t(name)}</MenuItem>)}
          </Select>
        </FormControl>
      </div>
      <div className={textBlock}>
        <Typography variant='body1'>
          {mostRecent.date && moment.utc(mostRecent.date).format('LL')}
        </Typography>
      </div>
    </Grid>
  )
}

export default TitleBlock
