import React from 'react'
import IconButton from '@material-ui/core/IconButton'

import styled from 'styled-components'


const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: ${props => props.top}px;
  bottom: ${props => props.bottom}px;
  right: ${props => props.right}px;
  z-index: 10000;
  padding: 5px;
`

const MapIconButton = ({ event, right, bottom, top, children }) => {
  return (
    <StyledIconButton
      right={right}
      bottom={bottom}
      top={top}
      onClick={event}
    >
      {children}
    </StyledIconButton>
  )
}

export default MapIconButton