import { GeoJsonLayer as DeckGJLayer} from '@deck.gl/layers'


export const GeoJsonLayer = ({
  minZoom,
  maxZoom,
  data,
  onHover,
}) => {
  return new DeckGJLayer({
    data,
    onHover,
    minZoom,
    maxZoom,
    pickable: true,
    opacity: 1,
    lineWidthMinPixels: 1,
    getLineColor: (props) => [0, 0, (185 * props.properties.intensity) + 70, ((150 * props.properties.intensity) + 50)],
    getFillColor: (props) => [0, 0, (185 * props.properties.intensity) + 70, ((150 * props.properties.intensity) + 50)],
  })
}
