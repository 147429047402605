import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import CheckIcon from '@material-ui/icons/Check'
import { useTranslation } from 'react-i18next'

import { titleCase } from '../../small-business-helper/helper'
import MapTooltip from '../map-tooltip-container'


const useStyles = makeStyles((theme) => ({
  tooltipCardPopup: {
    '&::-webkit-scrollbar': { display: 'none' },
    '& .MuiCardContent-root': {
      padding: '8px',
      '&:last-child': { paddingBottom: '16px' },
    },
  },
  tooltipButton: {
    marginTop: '0.5em',
    backgroundColor: theme.palette.primary.main,
    '&:hover': { backgroundColor: theme.palette.primary.main },
    color: theme.palette.white,
    textTransform: 'none',
    paddingRight: '20px',
    paddingLeft: '20px'
  },
  requestSubmittedButton: {
    borderColor: theme.palette.success.main,
    color: theme.palette.success.main,
    paddingRight: '20px',
    paddingLeft: '20px',
  },
  tooltipAddress: { marginBottom: '0.5em' },
  tooltipBusinessStatus: { marginTop: '0.5em' },
  tooltipNoGiftCard: {
    fontWeight: 'bold',
    marginTop: '0.5em',
  },
  tooltipPhone: {
    opacity: '0.5',
    marginBottom: '1em'
  },
  tooltipRequestText: {
    marginTop: '1em',
    marginBottom: '1em',
  },
  requestDivider: { marginBottom: '1em' },
}))

const SingleBusinessTooltip = ({ 
  clickedObject,
  handleRequest,
  requested 
}) => {
  const classes = useStyles()

  const { t } = useTranslation()
  const { x, y, h, w } = clickedObject
  return (
    <MapTooltip x={x} y={y} h={h} w={w} translate>
      <Card
        variant='outlined'
        className={classes.tooltipCardPopup}
        style={{ maxWidth: clickedObject.egift_url ? '220px' : '280px' }}
      >
        <CardContent>
          <Typography variant='subtitle2' gutterBottom>{titleCase(clickedObject.name)}</Typography>
          <Typography gutterBottom className={classes.tooltipAddress}>
            {titleCase(clickedObject.address)}
          </Typography>
          <Divider variant='fullWidth' />
          {clickedObject.egift_url && (
            <Button
              className={classes.tooltipButton}
              href={clickedObject.egift_url}
              target='_blank'
            >
              {t('purchaseEGiftCard')}
            </Button>
          )}
          {!clickedObject.egift_url && (
            <>
            <Typography gutterBottom className={classes.tooltipNoGiftCard}>
              {t('noOfferEGiftCard')}
            </Typography>
              <Typography gutterBottom className={classes.tooltipRequestText}>
                {t('sendRequestInfo')}
              </Typography>
              <Divider variant='fullWidth' className={classes.requestDivider} />
              {!requested ? (
                <Button
                  className={classes.tooltipButton}
                  onClick={() => handleRequest(clickedObject.id)}
                >
                  {t('sendRequest')}
                </Button>
              ) : (
                <Button
                  variant='outlined'
                  className={classes.requestSubmittedButton}
                  startIcon={<CheckIcon />}
                >
                  {t('requestSubmitted')}
                </Button>
              )}
            </>
          )}
        </CardContent>
      </Card>
    </MapTooltip>
  )
}

export default SingleBusinessTooltip
