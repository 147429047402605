export const PROVINCES = [
  { code: 'British Columbia', name: 'British Columbia' },
  { code: 'Alberta', name: 'Alberta' },
  { code: 'Saskatchewan', name: 'Saskatchewan' },
  { code: 'Manitoba', name: 'Manitoba' },
  { code: 'Ontario', name: 'Ontario' },
  { code: 'Quebec', name: 'Quebec' },
  { code: 'Northwest Territories', name: 'Northwest Territories' },
  { code: 'Yukon', name: 'Yukon' },
  { code: 'Nunavut', name: 'Nunavut' },
  { code: 'New Brunswick', name: 'New Brunswick' },
  { code: 'Newfoundland and Labrador', name: 'Newfoundland' },
  { code: 'Nova Scotia', name: 'Nova Scotia' },
  { code: 'Prince Edward Island', name: 'PEI' },
]
