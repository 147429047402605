const white = '#FFFFFF'
const black = '#000000'
const paletteLight = {
    type: 'light',
    white,
    black,
    primary: {main: '#1823b4'},
    secondary: {main: '#9e9e9e'},
    error: {main: '#ff0000'},
    warning: {main: '#ffd800'},
    info: {main: '#2196f3'},
    success: {main: '#00de11'},
    text: {
        primary: black,
        secondary: 'rgba(0, 0, 0, 0.54)',
        link: '#2196f3'
    },
    background: {
        highlight: '#f2f2f2',
        paper: white,
        default: white
    },
    chipButton: {
        inactiveColor: '#1823b4',
        activeColor: '#1823b4',
        inactiveText: '#1823b4',
        activeText: white,
    },
    dimmer: { backgroundColor: 'transparent' },
}

export default paletteLight
