import React from 'react'
import { NavLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Brightness4Icon from '@material-ui/icons/Brightness4'
import { useTranslation } from 'react-i18next'


const useStyles = makeStyles((theme) => ({
  topNav: {
    backgroundColor: theme.palette.primary,
    position: 'sticky',
    top: 0,
    left: 0
  },
  toolBar: {
    display: 'flex',
    flexDirection: 'row'
  },
  logo: {
    [theme.breakpoints.down('md')]: { margin: '0.8rem 0.8rem 0.8rem 1rem' },
    margin: '0.8rem 0.8rem 0.8rem 5rem',
    height: '2rem'
  },
  leftContent: {
    display: 'flex',
    width: '100%',
    flexGrow: 1,
    alignItems: 'center'
  },
  colorChip: {
    height: '10px',
    width: '10px',
    borderRadius: '15px',
    margin: '2px',
    float: 'left',
  },
  changeThemeButton: {
    width: '3rem',
    height: '3rem',
    '& > span': { paddingTop: '0.2em' },
  },
  largeButton: { padding: "1rem" },
  largeIcon: { fontSize: "3rem" },
  navLink: {
    textTransform: 'none',
    textDecoration: 'none',
    color: theme.palette.white,
    fontSize: '1.75rem'
  },
  navButton: {
    paddingTop: '1em',
    [theme.breakpoints.up('md')]: { marginLeft: theme.spacing(2) },
    [theme.breakpoints.down('md')]: { maxWidth: '7em' },
  },
  activeLink: { fontWeight: theme.typography.h6.fontWeight },
  changeLangButton: {color: 'white'}
}))

const TopNav = ({ onChangeTheme }) => {
  const classes = useStyles()
  const { i18n } = useTranslation()

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng)
  }

  return (
    <AppBar className={classes.topNav}>
      <Toolbar className={classes.toolBar}>
        <Box component='span' className={classes.leftContent}>
          <img className={classes.logo} src='./flaat-logo.svg' alt='logo'/>
          <Button className={classes.navButton} disableRipple disableFocusRipple>
            <NavLink exact activeClassName={classes.activeLink} className={classes.navLink} to='/'>COVID19 Dashboard</NavLink>
          </Button>
          <Button className={classes.navButton} disableRipple disableFocusRipple>
            <NavLink activeClassName={classes.activeLink} className={classes.navLink} to='/gift-local'>Gift Local</NavLink>
          </Button>
        </Box>
        <IconButton className={classes.changeThemeButton} aria-label='Change Theme' component='span' disableRipple disableFocusRipple onClick={onChangeTheme}>
          <Brightness4Icon style={{ fontSize: '2.5rem', color: 'white' }} />
        </IconButton>
        <IconButton
          className={classes.changeLangButton}
          style={i18n.language === 'en' ? { fontWeight: 900 } : { fontWeight: 300 }}
          disableRipple disableFocusRipple onClick={() => changeLanguage('en')}>
          EN
        </IconButton>
        <IconButton
          className={classes.changeLangButton}
          style={i18n.language === 'fr' ? { fontWeight: 900 } : { fontWeight: 300 }}
          disableRipple disableFocusRipple onClick={() => changeLanguage('fr')}>
          FR
        </IconButton>
      </Toolbar>
    </AppBar>
  )
}

export default TopNav
