import React, { useState, useEffect, useContext, useRef } from 'react'
import { useQueryParam, StringParam } from 'use-query-params'
import DeckGL from '@deck.gl/react'
import { FlyToInterpolator } from 'deck.gl'
import { WebMercatorViewport } from '@deck.gl/core'
import { StaticMap } from 'react-map-gl'
import { IconLayer } from '@deck.gl/layers'
import MyLocationIcon from '@material-ui/icons/MyLocation'
import FullscreenIcon from '@material-ui/icons/Fullscreen'
import Fullscreen from 'react-full-screen'

import { AppContext } from '../../context'
import { useMobile } from '../../small-business-helper/hooks'
import { isSameLocation } from '../../small-business-helper/helper'

import IconClusterLayer from '../../layers/cluster-layer'
import SingleBusinessTooltip from './single-bus-tooltip'
import MultiBusinessTooltip from './multi-business-tooltip'
import MapIconButton from '../map-icon-button'

import userIcon from '../icons/user-location.png'
import iconMapping from '../icons/cluster.json'
import eqIcon from '../icons/cluster.png'
import narcityIcon from '../icons/cluster_narcity.png'


const INIT_VIEW_STATE = {
  pitch: 0,
  bearing: 0,
  transitionDuration: 300,
  transitionInterpolator: new FlyToInterpolator(),
}

const USER_ICON_MAPPING = { marker: { x: 0, y: 0, width: 1024, height: 1024 }}

const updateViewState = ({ deck }, flag = true) => (callback) => {
  if (flag) {
    const viewState = {
      ...deck.viewState,
      ...deck.viewState['default-view'],
      height: deck.height,
      width: deck.width,
    }
    const viewport = new WebMercatorViewport(viewState)
    const [w, n] = viewport.unproject([0, 0])
    const [e, s] = viewport.unproject([viewport.width, viewport.height])
    return callback({ w, n, e, s })
  }
}

const SmallBusinessMap = ({
  businesses,
  userLocation,
  searchLocation,
  setSearchLocation,
  activeBusiness,
  setActiveBusiness,
  setHighlight,
  highlight,
  handleViewportChange,
  initialTransition,
  handleInitialTransition, // flag whether userLocation has been accounted for in current VP
  requests,
  handleRequest,
  error,
}) => {
  const { isLightTheme, location } = useContext(AppContext)
  const [clickedObject, setClickedObject] = useState(false)
  const isMobile = useMobile()
  const [layers, setLayers] = useState([])
  const [domain] = useQueryParam('location', StringParam)
  let iconAtlas = domain ? narcityIcon : eqIcon

  useEffect(() => {
    const onHover = (params) => {
      const { object } = params
      object && (object.id || object.cluster_id) ? setHighlight(object.id || object.cluster_id) : setHighlight(false)
    }

    const onClick = (params) => { 
      const { x, y, object, layer, coordinate, objects } = params
      const [ longitude, latitude ] = coordinate
      if(object.cluster){
        if(isSameLocation(objects)){
          // to zoom in on each cluster icon
          setClickedObject({ multi: objects, x, y, w: deckRef.current.deck.width, h: deckRef.current.deck.height })
        } else {
          setActiveBusiness({ longitude, latitude, zoom: layer.state.z + 2})
        }
      } else {
        setClickedObject({ ...object, x, y, w: deckRef.current.deck.width, h: deckRef.current.deck.height })
      }
    }

    // https://deck.gl/showcases/gallery/icon-layer
    // https://github.com/visgl/deck.gl/blob/master/docs/layers/icon-layer.md
    setLayers([
      new IconClusterLayer({
        highlight,
        onClick,
        onHover,
        data: businesses,
        pickable: true,
        getPosition: d => d.coordinates,
        iconAtlas,
        iconMapping,
        id: 'icon-cluster',
        sizeScale: 60,
        superclusterZoom: 20,
        getSuperclusterRadius: (viewportZoom, sizeScale) => viewportZoom > 15 ? sizeScale/3 : sizeScale
      }),
      new IconLayer({
        id: 'focused-location',
        data: [{
          coordinates: [
            searchLocation.longitude || userLocation.longitude,
            searchLocation.latitude || userLocation.latitude,
          ],
        }],
        iconAtlas: userIcon,
        iconMapping: USER_ICON_MAPPING,
        getIcon: d => 'marker',
        sizeScale: 5,
        getPosition: d => d.coordinates,
        getSize: d => 5,
        getColor: d => [255, 0, 0],
      }),
    ])
  }, [businesses, highlight, setHighlight, userLocation, searchLocation, setActiveBusiness, iconAtlas])

  const deckRef = useRef(null)

  const [language] = useQueryParam('lang', StringParam)

  const [viewState, setViewState] = useState({
    ...INIT_VIEW_STATE,
    latitude: language === 'fr' ? 51.247311 : 54.602826,
    longitude: language === 'fr' ? -73.187902 : -100.257794,
    zoom: language === 'fr' ? 4 : 3,
  })
  const [fullScreenMode, setfullScreenMode] = useState(false)

  const fullScreenToggler = () => {
    setfullScreenMode(!fullScreenMode)
  }

  useEffect(() => {
    setViewState(prevState => ({
      ...prevState,
      ...userLocation,
      ...searchLocation,
      ...activeBusiness,
      onTransitionEnd: () => {
        updateViewState(deckRef.current)(handleViewportChange)
        if (userLocation.latitude || searchLocation.latitude) {
          handleInitialTransition(true)
        }
      },
    }))
  }, [handleInitialTransition, handleViewportChange, userLocation, searchLocation, activeBusiness])

  const handleRequestFullscreen = (id) => {
    setfullScreenMode(false)
    handleRequest(id)
  }

  return (
    <Fullscreen
      enabled={fullScreenMode}
      onChange={isFull => setfullScreenMode(isFull)}
    >
    <div style={{ height: '100%', width: '100%', position: 'absolute' }}>
      <DeckGL
        ref={deckRef}
        onLoad={() => {
          if (userLocation.latitude || searchLocation.latitude) {
            updateViewState(deckRef.current)(handleViewportChange)
            handleInitialTransition(true)
          }
        }}
        onViewStateChange={({ viewState, interactionState }) => {
          // any movement of the map should close the tooltip
          setClickedObject(false)
          if (!interactionState.inTransition && !interactionState.isDragging && viewState.zoom > 11) {
            updateViewState(deckRef.current, initialTransition)(handleViewportChange)
            // TODO: should user be able to manually pan/zoom to a location to start a search?
            // will result in distance: NaN right now
          }
        }}
        onDragStart={() => setClickedObject(false)}
        onDragEnd={() => { updateViewState(deckRef.current, initialTransition)(handleViewportChange) }}
        initialViewState={viewState}
        controller={true}
        layers={layers}
        onClick={({ layer })=> {
          if (!layer){
              setClickedObject(false)
          }
        }}
      >
        <StaticMap
          mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
          mapStyle={(isLightTheme || location) ? 'mapbox://styles/mapbox/light-v9' : 'mapbox://styles/mapbox/dark-v9'}
        />
        {(!error && userLocation.latitude) &&
        <MapIconButton 
          event={() => {
            setSearchLocation({})
            setActiveBusiness({})
          }} 
          bottom={35} right={5}
        >
          <MyLocationIcon style={{ width: '2em', height: '2em' }} />
        </MapIconButton>}
        {
          (
            isMobile && (
              <MapIconButton event={() => fullScreenToggler()} top={10} right={5}>
                <FullscreenIcon style={{ width: '2em', height: '2em' }} />
              </MapIconButton>
            ))
        }
      </DeckGL>
      {clickedObject && 
          (clickedObject.multi
          ? 
          <MultiBusinessTooltip
            clickedObject={clickedObject}
            setClickedObject={setClickedObject}
          />
          :
          <SingleBusinessTooltip
            requested={requests[clickedObject.id] && requests[clickedObject.id].requested}
            handleRequest={handleRequestFullscreen}
            clickedObject={clickedObject}
          />
          )}
    </div>
    </Fullscreen>
  )
}

export default SmallBusinessMap
