export const titleCase = (str) => {
    let splitStr = str.toLowerCase().split(' ').map( word => (`${word.charAt(0).toUpperCase()}${word.substring(1)}`))
    return splitStr.join(' ')
   }

export const formatPhone = number => {
  if (!number) {
    return ''
  }

  const numberNoSpacesNoSymbols = number.replace(/[^\d]/g, '').replace(/^1/g, '')
  const areaCode = numberNoSpacesNoSymbols.slice(0, 3)
  const nextThreeDigits = numberNoSpacesNoSymbols.slice(3,6)
  const lastFourDigits = numberNoSpacesNoSymbols.slice(6)
  return `(${areaCode}) ${nextThreeDigits}-${lastFourDigits}`
}

export const isSameLocation = ( array ) => {
    // Converts numeric degrees to radians
    const toRad = (value) => {
        return value * Math.PI / 180
    }

    const calcCrow = (Lat1, lon1, Lat2, lon2) => {
      const R = 6371000 // Radius of the earth in m
      const dLat = toRad(Lat2-Lat1)
      const dLon = toRad(lon2-lon1)
      const lat1 = toRad(Lat1)
      const lat2 = toRad(Lat2)

      const a = (Math.sin(dLat/2) * Math.sin(dLat/2)) +
        (Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2)) 
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a))
      const distance = R * c
      return distance
    }

    //compare distances
    let isSame = array.every((element, i, array) => {
      let distance = calcCrow(array[0].lat, array[0].lon, element.lat, element.lon)
      return distance <= 2
    })

    return isSame

    
}