import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { titleCase } from '../small-business-helper/helper'
import CheckIcon from '@material-ui/icons/Check'
import { useTranslation } from 'react-i18next'

import { formatPhone } from '../small-business-helper/helper'


const useStyles = makeStyles((theme) => ({
    root: {width: '100%', marginBottom: '20px'},
    heading: {...theme.typography.subtitle1, textTransform: 'none',},
    contact: {
      color: theme.palette.text.secondary,
      fontSize: theme.typography.caption.fontSize,
    },
    highlighted: {backgroundColor: theme.palette.background.highlight},
    button: {
      textTransform: 'none',
      paddingRight: '20px',
      paddingLeft: '20px',
    },
    requestButton: {
      '&:hover': { backgroundColor: theme.palette.primary.main },
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white,
    },
    requestedButton: {
      backgroundColor: theme.palette.background.paper,
      borderColor: theme.palette.success.main,
      color: theme.palette.success.main,
    },
}))

const ListItem = ({
  name,
  address,
  phone,
  egift_url,
  id,
  lat,
  lon,
  distance,
  expanded,
  setExpanded,
  highlight,
  setHighlight,
  handleRequest,
  requested,
  resetError,
  requestError,
}) => {
  const classes = useStyles()

  const { t, i18n } = useTranslation()

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ?  panel : false)
  }
  // TODO: what if they've requested, then hit "No Thanks", but want to subcribe?
  // conditionally render a button to open the modal w/o request
  return (
    <div
      className={classes.root}
      onMouseEnter={() => {
        setHighlight(id)
      }}
      onMouseLeave={()=> {
        setHighlight(false)
      }}
    >
      <ExpansionPanel
        variant='outlined'
  		  expanded={expanded === id}
        onChange={handleChange(id)}
        {...(highlight === id
          ?
          {className: classes.highlighted}
          :
          null)
        }
  	  >
        <ExpansionPanelSummary
          aria-controls={`${name}-${id}-business-header`}
    		  id={`${name}-${id}-business-header`}
    		  onClick={() => resetError()}
        >
          <Grid container alignItems='center'>
            <Grid item xs={1}>
              <Typography>
                <ExpandMoreIcon fontSize='large'/>
              </Typography>
            </Grid>
            <Grid item xs={11} md={8}>
              <Typography className={classes.heading}>{titleCase(name)}</Typography>
              <Typography>{titleCase(address)}  • { i18n.language === 'fr' && 'à' } {Math.round(distance / 10) / 100} {t('kmAway')}</Typography>
            </Grid>
            <Grid item xs={12} md={3} style={{ flexGrow: 1, textAlign: 'right' }}>
              <Typography className={classes.contact}>
                {formatPhone(phone)}
              </Typography>
            </Grid>
          </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid item xs={1} />
          <Grid item xs={12} md={7} className={classes.listItemBody}>
            {egift_url
              ?
              <Button
                className={`${classes.button} ${classes.requestButton}`}
                target='_blank'
                href={egift_url}
              >
                {t('purchaseEGiftCard')}
              </Button>
              :
              <>
                <Typography style={{fontWeight: 600}}>
                  {t('noEGiftCard')}
                </Typography>
                <Typography >
                  {t('sendRequestInfo')}
                </Typography>
                <br />
                {requested ? (
                  <Button
                    variant='outlined'
                    className={`${classes.button} ${classes.requestedButton}`}
                    startIcon={<CheckIcon />}
                  >
                    {t('requestSubmitted')}
                  </Button>
                ) : (
                  <>
                    <Button
                      className={`${classes.button} ${classes.requestButton}`}
                      onClick={() => handleRequest(id)}
                    >
                      {t('sendRequest')}
                    </Button>
                    {requestError && (<Typography variant='body1'>{t('errorRequest')}</Typography>)}
                  </>
                )}
              </>
            }
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  )
}

export default ListItem
