/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import Divider from '@material-ui/core/Divider'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputLabel from '@material-ui/core/InputLabel'
import BusinessIcon from '@material-ui/icons/Business'
import RoomIcon from '@material-ui/icons/Room'
import SearchIcon from '@material-ui/icons/Search'
import { useBusinessSearch } from './hooks'
import { titleCase } from './helper'
import { AppContext } from '../context'
import { useTranslation } from 'react-i18next'
import { useMobile } from '../small-business-helper/hooks'
import HowItWorksModal from './how-it-works-modal'


const useStyles = makeStyles((theme) => ({
  search: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  searchBar: {
    display: 'flex', alignItems: 'center',
    [theme.breakpoints.down('sm')]: { fontSize: '1.5rem' }
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  title: {
    letterSpacing: '0.48px',
    lineHeight: '1.38',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      margin: 'auto auto 0 auto',
      width: '300px',
      height: '106px'
    }
  },
  suggestionDiv: {
    color: theme.palette.text.primary,
    border: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.paper,
  },
  sugDivType: theme.typography.subtitle1,
  suggestionDivItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    padding: '0.5rem',
    '&:hover': { backgroundColor: theme.palette.background.highlight },
    fontSize: theme.typography.body2
  },
  container: {
    [theme.breakpoints.down('sm')]: { padding: '0px 32px 0px 32px' },
    margin: 0,
    flex: 'none',
  },
  howItWorksModal: { [theme.breakpoints.down('sm')]: { justifyContent: 'center' } },
  searchInput: { fontSize: 16 },
}))

const SuggestionDiv = styled.div`
  position: absolute;
  top: ${props => props.top}px;
  width: ${props => props.width}px;
  z-index: 9999;
  padding: 10px;
`


const SearchContainer = ({ search }) => {
  const classes = useStyles()
  const { location } = useContext(AppContext)

  const {
    searchInputRef,
    blur,
    inputValue,
    searchTerm,
    setSearchTerm,
    setInputValue,
    searchResults,
    searchResultsLoading,
    searchResultsError,
  } = useBusinessSearch()

  const { t, i18n } = useTranslation()
  const isMobile = useMobile()

  const getResults = (array) => {
    const display = bus => bus.type === 'location' ? `${bus.name.split(',')[0]} - ${bus.name.split(',')[1]}` : titleCase(bus.name)
    return array.map(bus => (
      <div
        key={bus.id}
        onClick={() => {
          setInputValue(display(bus))
          search(bus)
        }}
        className={classes.suggestionDivItem}
      >
        {bus.type === 'location'
          ? <RoomIcon style={{ marginRight: '5px', fontSize: '2rem' }} /> : <BusinessIcon style={{ marginRight: '5px', fontSize: '2rem' }} />}
        <Typography>{display(bus)}</Typography>
      </div>
    ))
  }

  const locationResults = getResults(searchResults.filter(result => result.type === 'location'))
  const businessResults = getResults(searchResults.filter(result => !result.type))

  const [focus, setFocus] = useState(false)

  return (
    <Grid container className={classes.container}>
      <Grid container item xs={12} className={classes.howItWorksModal}>
        {!location && (
          <Grid item xs={12}>
            <Typography className={classes.title} variant='h5' gutterBottom>
              {t('purchaseHeader')}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <HowItWorksModal/>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container className={classes.searchContainer}>
          <Grid
            item
            xs={12}
            onBlur={() => {
              if (!focus) {
                blur()
              }
            }}
            onFocus={() => {
              setSearchTerm(inputValue)
            }}
          >
            <FormControl variant='outlined' fullWidth>
              <InputLabel
                htmlFor='search-businesses'
                className={classes.searchBar}
              >
                <SearchIcon style={{ marginRight: 5 }} />
                {t('search')}
              </InputLabel>
              <OutlinedInput
                className={classes.searchInput}
                ref={searchInputRef}
                id='search-businesses'
                value={inputValue}
                onChange={(e) => setSearchTerm(e.target.value)}
                labelWidth={i18n.language === 'en' ? (isMobile ? 290 : 340) : (isMobile ? 310 : 360)}
                autoComplete='off'
              />
              {searchTerm !== '' && (
                <SuggestionDiv
                  className={classes.suggestionDiv}
                  width={searchInputRef.current.offsetWidth}
                  top={searchInputRef.current.offsetHeight}
                  onMouseEnter={() => setFocus(true)}
                  onMouseLeave={() => setFocus(false)}
                >
                  {searchResultsLoading && <div><CircularProgress color='primary' /></div>}
                  {(!searchResultsLoading && !searchResultsError) && (
                    searchResults.length
                      ?
                      <>
                        <div>
                          <Typography className={classes.sugDivType}>Business</Typography>
                          {businessResults}
                        </div>
                        <Divider />
                        <div>
                          <Typography className={classes.sugDivType}>Location</Typography>
                          {locationResults}
                        </div>
                      </>
                      :
                      <div>{t('noBusinessesFound')}</div>
                  )}
                </SuggestionDiv>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SearchContainer