import React from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import moment from 'moment'

import CustomTooltip from './tooltip'


const timeFormat = d => moment(d).utc().format('MMM Do')

const SimpleLine = ({ data, dateKey, valueTotal, valueNew, scaleType }) => (
  <ResponsiveContainer>
    <LineChart
      data={data}
      margin={{ top: 5, right: 30, left: 0, bottom: 5 }}
    >
      <XAxis tickFormatter={timeFormat} dataKey={dateKey} />
      {scaleType === 'log' 
        ?
        <YAxis scale='log' domain={['auto', 'auto']} interval={8}/>
        :
        <YAxis />
      }
      <CartesianGrid strokeDasharray='3 3' />
      <Tooltip content={<CustomTooltip />} />
      <Line name='total' type='monotone' dataKey={valueTotal} stroke='#3542E4' dot={false} />
      <Line name='new' type='monotone' dataKey={valueNew} stroke='#E45235' dot={false} />
    </LineChart>
  </ResponsiveContainer>
)

export default SimpleLine