import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'


const useStyles = makeStyles(theme => ({
	noList: {
	  flex: 'none',
	  display: 'flex',
	  flexDirection: 'row',
	  justifyContent: 'center',
	  width: '100%',
	  [theme.breakpoints.down('md')]: {
	    flexDirection: 'column',
	    marginTop: '5rem',
	    alignItems: 'center',
	  },
	},
	noListRight: {
	  maxWidth: 300,
	  [theme.breakpoints.down('md')]: {maxWidth: 200},
	  textAlign: 'center',
	  alignItems: 'center'
	},
	personImgWrapper: { flexGrow: 1 },
	personImg: {
	  height: 200,
	  width: 200,
	  marginBottom: '3rem',
	},
}))

const MSG_MAP = {
  'start': {
    topMsg: 'Looking for business near you?',
    img: './person-3.svg',
    botMsg: 'Search your location or a store name to view the list of businesses',
  },
  'none': {
    topMsg: 'No businesses found',
    img: './person-2.svg',
    botMsg: 'Try different filters or search for new businesses',
  }
}

const InfoImage = ({ msg }) => {
	const classes = useStyles()
	const { t } = useTranslation()

	MSG_MAP['start'].topMsg = t('businessNearPrompt')
	MSG_MAP['none'].topMsg = t('noBusinessesFound')

	return (
		<Box className={classes.noList}>
		  <Box className={classes.noListRight}>
		    <Typography variant='h6' style={{ marginBottom: '4.5rem' }}>
		      {MSG_MAP[msg].topMsg}
		  </Typography>
		  <Box className={classes.personImgWrapper}>
		    <img className={classes.personImg} src={MSG_MAP[msg].img} alt='Person Searching'/>
		  </Box>
		    <Typography variant='body1' style={{ fontWeight: 200, marginBottom: '3rem' }} gutterBottom>
		     {t('searchInfo')}
		  </Typography>
		  </Box>
		</Box>
	)
}

export default InfoImage
