const white = '#FFFFFF'
const black = '#000000'
const paletteDark = {
    type: 'dark',
    white,
    black,
    primary: {main: '#4b55e7'},
    secondary: {main: '#9e9e9e'},
    error: {main: '#ff0000'},
    warning: {main: '#ffd800'},
    info: {main: '#2196f3'},
    success: {main: '#00de11'},
    text: {
        primary: white,
        secondary: 'rgba(255, 255, 255, 0.68)',
        link: '#2196f3'
    },
    background: {
        highlight: '#404040',
        paper: '#202020',
        default: '#101010'
    },
    chipButton: {
        inactiveColor: '#4b55e7',
        activeColor: '#4b55e7',
        inactiveText: '#4b55e7',
        activeText: white,
    },
    dimmer: { backgroundColor: 'black' },
}
export default paletteDark
