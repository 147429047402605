const white = '#FFFFFF'
const black = '#000000'
const extThemes = {
    narcity: {
        type: 'light',
        white,
        black,
        primary: {main: '#ff2634'},
        secondary: {main: '#9e9e9e'},
        error: {main: '#ff0000'},
        warning: {main: '#ffd800'},
        info: {main: '#2196f3'},
        success: {main: '#00de11'},
        text: {
            title: '#ff2634',
            primary: black,
            secondary: 'rgba(0, 0, 0, 0.54)',
            link: '#2196f3'
        },
        background: {
            highlight: '#f2f2f2',
            paper: white,
            default: white
        },
        chipButton: {
            inactiveColor: black,
            activeColor: '#ff2634',
            inactiveText: black,
            activeText: white,
        },
        dimmer: { backgroundColor: 'transparent' },
    }
}

export default extThemes
